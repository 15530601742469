import React, { Component } from "react";
import "./Photo.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TrackorderStyle from "../TrackOrders/TrackorderStyle";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useParams } from "react-router-dom";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Pdf from "react-to-pdf";
import moment from "moment";
import { getBaseURL } from "../../../api/apiManagement";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import clsx from "clsx";

const PhotoTab = (props) => {
  let dispalyData;
  const { id } = useParams();
  const url = getBaseURL() + "/vendor/api/v1/photoservice";
  const { classes } = props;
  const [data, setData] = React.useState();
  const [flag, setFlag] = React.useState(false);
  const [dataParcel, setDataParcel] = React.useState();
  const [datanew, setDataNew] = React.useState();
  const [can, setCan] = React.useState(true);
  const [photoStatus, setphotoStatus] = React.useState("Pending");
  const ref = React.createRef();

  React.useEffect(() => {
    axios
      .get(url, {
        params: {
          userId: localStorage.getItem("userId"),
          company_id: localStorage.getItem("companyId"),
          order_status: "Pending",
        },
      })
      .then((response) => {
        console.log(response);
        let mydata = [];
        mydata = response.data.photo_service.filter((item)=>item.order_status === "Pending")
        setData(mydata);
      });
  }, [url]);
  React.useEffect(() => {
    setCan(false);
    //  setDataParcel(items);
  }, [url]);
  const handlePhoto = (event) => {
    let mydata = [];
    setphotoStatus(event.target.value)
    axios
    .get(url, {
      params: {
        userId: localStorage.getItem("userId"),
        company_id: localStorage.getItem("companyId"),
        order_status: event.target.value,
      },
    })
    .then((response) => {
      console.log(response);
      mydata = response.data.photo_service.filter((item)=>item.order_status === event.target.value)
      setData(mydata);
    });
  };
  const handleDownload = (imageUrl) => {
    window.location.assign(imageUrl)
    console.log("hh", imageUrl);
    // imageUrlToBase64(imageUrl)
    //   .then((base64Data) => {
    //     console.log("Base64-encoded image data:", base64Data);
    //     const link = document.createElement("a");
    //     link.href = base64Data;
    //     link.download = "downloaded-image.jpg";
    //     link.click();
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  async function imageUrlToBase64(imageUrl) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to base64:", error);
      throw error;
    }
  }
  return (
    <>
      {!can ? (
        <>
          <Grid item className={classes.filterContainerPhoto}>
            <Grid item xs={12} md={6} lg={6}>
              <div></div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.filterItem}>
              {/* <ToggleButtonGroup
            fullWidth
            color="info"
            id="pickupAA"
            value={photoStatus}
            exclusive
            // onChange={(e, value) => setAlignmentPickup1(value)}
            className={classes.timeGroup}
            // onClick={(e) => e.stopPropagation()}
          >
            <ToggleButton
              className={clsx("toggledts", "toggle-button-left")}
              value="AM"
            >
            Pending
            </ToggleButton>
            <ToggleButton
              className={clsx("toggledts", "toggle-button-right")}
              value="PM"
            >
               Completed
              
            </ToggleButton>
          </ToggleButtonGroup> */}
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={photoStatus}
                  onChange={handlePhoto}
                >
                  <FormControlLabel
                    className={classes.radioLabel}
                    value="Pending"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Active"
                  />
                   <FormControlLabel
                    className={classes.radioLabel}
                    value="Cancelled"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Cancelled"
                  />

                  <FormControlLabel
                    className={classes.radioLabel}
                    value="Delivered"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Completed"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {data !== undefined && data && data.length !== 0 ? (
            (dispalyData = data.map((item, index) => (
              <Box className={classes.deliveryIn}>
                <Grid container ref={ref} className={classes.Invoiced}>
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.stepperIn}
                  >
                    {item.status && item.status === "Delivered"}
                    <Grid xs={12} md={3} lg={3}>
                      <p className={classes.stepperItem}>
                        <span className={classes.stepperBold}>
                          Invoice no :{item.invoice_no}
                          {/* {moment(item.updatedAt).format("DD/MM/YYYY")} */}
                        </span>
                      </p>
                    </Grid>
                    <Grid xs={12} md={3} lg={3}>
                      <p className={classes.stepperItem}>
                        Status :{" "}
                        <span className={classes.stepperBold}>
                          {item.order_status}
                        </span>
                      </p>
                    </Grid>
                    <Grid xs={12} md={3} lg={3}>
                      <p className={classes.stepperItem}>
                        Order ID :{" "}
                        <span className={classes.stepperBold}>
                          {item.order_id ? item.order_id : ""}
                        </span>
                      </p>
                    </Grid>
                    {item.price ? (
                      <Grid xs={12} md={3} lg={3}>
                        <p className={classes.stepperItem}>
                          Price :{" "}
                          <span className={classes.stepperBold}>
                            {item.price} &nbsp;AED
                          </span>
                        </p>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.stepperIn}
                  >
                    {item.order_by ? (
                      <Grid xs={12} md={6} lg={6}>
                        <p className={classes.stepperItem}>
                          Ordered by :{" "}
                          <span className={classes.stepperBold}>
                            {item.order_by}
                          </span>
                        </p>
                      </Grid>
                    ) : (
                      <></>
                    )}
                     {item.user_email_address ? (
                      <Grid xs={12} md={6} lg={6}>
                        <p className={classes.stepperItem}>
                          Pickup :{" "}
                          <span className={classes.stepperBold}>
                            {item.pickupDate ? moment(item.pickupDate).format("DD-MM-YYYY") : moment(item.createAt).format("DD-MM-YYYY")}
                          </span>
                        </p>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    </Grid>
                  <Grid
                    container
                    className={classes.invoiceContent}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.orderStatus}
                    >
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        className={classes.stepped}
                      >
                        <div className={classes.lineDot}>
                          <img src="./Images/e1.svg"></img>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        lg={8}
                        className={classes.ddpick}
                      >
                        <span className={classes.lpick}>
                          <b>Client Fulfilment Address</b>{" "}
                        </span>
                        <br />
                        <span className={classes.lpickDataPhoto}>
                          {item.place} &nbsp;
                          {/*<u className={classes.modifyButton}>Modify</u><span className={classes.modifyButton}> / </span><u className={classes.modifyButton}>Reschedule</u>*/}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        className={classes.ddpick}
                      >
                        <span className={classes.lpick}>
                          <b>Photo Specification</b>{" "}
                        </span>
                        <br />
                        <span className={classes.lpickData}>
                          {item.photo_specification} &nbsp;
                          {/*<u className={classes.modifyButton}>Modify</u><span className={classes.modifyButton}> / </span><u className={classes.modifyButton}>Reschedule</u>*/}
                        </span>
                      </Grid>
                    </Grid>
                    {item.image_url ? (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className={classes.ddpick}
                        sx={{ paddingTop: "30px" }}
                      >
                        <span className={classes.lpick}>
                          <b>Download image here</b>{" "}
                        </span>
                        {/* <div className={classes.download_image} onClick={handleDownload}>
                            <img width="250px" height="200px"  src="../Images/people.jpg"></img>
                          </div> */}

                        {item.image_url.map((image) => (
                          <>
                            <div className="downloadable-image-container">
                              <div className="image-wrapper">
                                <img
                                  width="200px"
                                  height="200px"
                                  src={image}
                                  alt="Displayed Image"
                                />
                              </div>
                            </div>

                            <div className="download_sec">
                              <button
                                className="download-button"
                                onClick={() => handleDownload(image)}
                              >
                                <span>Download</span> &nbsp;&nbsp;
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                >
                                  <g
                                    id="download"
                                    transform="translate(-2.5 -2.5)"
                                  >
                                    <path
                                      id="Path_47"
                                      data-name="Path 47"
                                      d="M21,15v4a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V15"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    />
                                    <path
                                      id="Path_48"
                                      data-name="Path 48"
                                      d="M7,10l5,5,5-5"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    />
                                    <path
                                      id="Path_49"
                                      data-name="Path 49"
                                      d="M12,15V3"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </>
                        ))}
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )))
          ) : (
            <>
              <div className={classes.pending}>
                <img className={classes.pend} src="../Images/jf2.png"></img>
              </div>
              <span className={classes.pendingInfo}>No Orders</span>
              <br />
              <br />
              <br />
              <br />
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          <div className="center2">
            <div class="loader"></div>
          </div>{" "}
        </>
      )}
    </>
  );
};

export default withStyles(TrackorderStyle)(PhotoTab);
