import { Grid } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import AuthenticationStyle from "../../Styles/AuthenticationStyle";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Input, InputAdornment } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import KeyIcon from "@mui/icons-material/Key";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link, useNavigate } from "react-router-dom";
import { loginAPI } from "../../api/apiManagement";
import axios from "axios";
import { getBaseURL } from "../../api/apiManagement";
import { set } from "date-fns/esm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getFirestore, onSnapshot, collection } from "@firebase/firestore";
import { useEffect } from "react";
const ImgGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    // marginLeft: "5px",
    marginTop: "5px",
    // marginRight:"5px"
  },
  [theme.breakpoints.down("xl")]: {
    // marginLeft: "5px",
    marginTop: "5px",
    marginRight: "95px",
  },
  [theme.breakpoints.down("md")]: {
    marginRight: "0px",
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
  },
}));
const Image = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: "555px",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "90%",
  },
}));
const HeadingGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    marginLeft: "100px",
    marginRight: "20px",
  },
  [theme.breakpoints.down("xl")]: {
    marginLeft: "5px",
    marginRight: "20px",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px",
    marginRight: "20px",
  },
}));
const AuthContent = (props) => {
  let slideIndex = 0;
  const { classes } = props;

  const newUrl = getBaseURL() + "/vendor/api/login";

  const navigate = useNavigate();
  const [data, setData] = React.useState({
    user_name: "",
    password: "",
  });
  const [data1, setData1] = React.useState({});
  //VALIDATION Flags
  const [errorFlag, setErrorFlag] = React.useState(false);
  const [errorFlagWrong, setErrorFlagWrong] = React.useState(false);
  const [errorFlagPass, setErrorFlagPass] = React.useState(false);
  const [errorFlagPassWrong, setErrorFlagPassWrong] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [forgot, setForgot] = React.useState("showLogin");
  const [Login, setLogin] = React.useState("showForgot");
  const [change, setChange] = React.useState("showForgot");
  const [res, setRes] = React.useState("");
  const [forSuccess, setSuccess] = React.useState(false);
  const [newPass, setPass] = React.useState(false);
  const [forgotFlag, setForgotFlag] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const changeClass = () => {
    setForgot("showForgot");
    setLogin("showLogin");
    setChange("showForgot");
  };
  const images = [
    "./Images/newfront.png",
    "./Images/f3.png",
    "./Images/acc1.png",
    "./Images/f5.png",
  ];
  const [currentImage, setCurrentImage] = React.useState(
    "./Images/newfront.png"
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * 4)]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  const url1 = getBaseURL() + "/vendor/api/v1/forgotpassword/";
  const url2 = getBaseURL() + "/vendor/api/v1/corporateuser";

  const requestLink = () => {
    setIsButtonDisabled(true);
    axios.post(url1, data1).then((res) => {
      setSuccess(true);
    });
    //setForgot('showForgot');
    // setLogin('showForgot');
    //setChange('showLogin')
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handle(e) {
    setForgotFlag(true)
    //const newTableData = { ...tableData }
    //const selectedStatus = e.target.value;
    data[e.target.id] = e.target.value;
  }
  function handle1(e) {
    setForgotFlag(true)
    //const newTableData = { ...tableData }
    //const selectedStatus = e.target.value;
    data1[e.target.id] = e.target.value;
  }
  const showPass = () => {
    setPass(!newPass);
  };
  async function login() {
    setErrorFlag(false);
    setErrorFlagWrong(false);
    setErrorFlagPass(false);
    setErrorFlagPassWrong(false);
    //let payload = {}
    // payload["user_name"] = "admin"
    // payload["password"] = "jiffy"
    // try{
    //const res = loginAPI({
    //  user_name:"admin",
    // password:"jiffy"

    //});
    // console.log(res.data)
    let params = {};
    await axios.post(newUrl, data).then((res) => {
      setRes(res.data.e);
      try {
        if (res.data.status === "success") {
          navigate("/services");
          localStorage.setItem("userId", res.data.userId);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("phone", res.data.phone);
          localStorage.setItem("previlage", res.data.previlage);
          localStorage.setItem("company", res.data.company_name);
          localStorage.setItem("companyId", res.data.company_id);
          localStorage.setItem("error", res.data.temp_message);
          localStorage.setItem("released", res.data.is_released);
          sessionStorage.setItem("userId", res.data.userId);
          sessionStorage.setItem("email", res.data.email);
          sessionStorage.setItem("name", res.data.name);
          sessionStorage.setItem("phone", res.data.phone);
          sessionStorage.setItem("previlage", res.data.previlage);
          sessionStorage.setItem("company", res.data.company_name);
          sessionStorage.setItem("companyId", res.data.company_id);
          sessionStorage.setItem("error", res.data.temp_message);
          sessionStorage.setItem("released", res.data.is_released);
          sessionStorage.setItem("token", res.data.token);
          if (
            res.data.previlage === "jiffyadmin" &&
            res.data.companies_info.length
          ) {
            localStorage.setItem(
              "company",
              res.data.companies_info[0].company_name
            );
            localStorage.setItem(
              "companyId",
              res.data.companies_info[0].company_id
            );
          }
        } else {
          document.getElementById("user_name").focus();
          {
            /*} if (data.user_name === '') {
              setErrorFlag(true)
            }
            else if (data.user_name !== "admin") {
              setErrorFlagWrong(true)
            }
            if(data.password === ''){
              setErrorFlagPass(true)
            }*/
          }

          setErrorFlagPassWrong(true);
        }
        const firebaseConfig = {
          apiKey: "AIzaSyApfVOAcPyKnWgMlDCQlrlJvDLro2LW4Xo",
          authDomain: "jiffy-7c780.firebaseapp.com",
          projectId: "jiffy-7c780",
          storageBucket: "jiffy-7c780.appspot.com",
          messagingSenderId: "858697568951",
          appId: "1:858697568951:web:c314027e738f1df16db332",
          measurementId: "G-ZMK5HQEQFN",
        };
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging();

        return getToken(messaging, {
          vapidKey:
            "BA73PZSy4WoyebsLx6HOwYoxZVM4jbh_ADhqxmlkhviHdqFRBiJojbRUCQx2foyIP2c5NSk52a3xsuEfup_vK_s",
        })
          .then((currentToken) => {
            if (currentToken) {
              //console.log('current token for client: ', currentToken);

              localStorage.setItem("token", currentToken);
              // Perform any other neccessary action with the token
            } else {
              // Show permission request UI
              //console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            //console.log('An error occurred while retrieving token. ', err);
          });
      } catch (error) {
        console.error("TRy AGAIN");
        return <></>;
      }
    });
    // login(payload)
    // navigate('/services')
    //}
    setTimeout(() => {}, 1000);
  }

  return (
    <>
      <div className={classes.coperate_start2}>
        <Grid container className={classes.ht}>
          {/*} <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12} className="location"> 
                  <div className={classes.location}>
                    <p className='location-text'>
                     <LocationOnIcon className={classes.locIcon}></LocationOnIcon> United Arab Emirates
                    </p>
                  </div>     
            </Grid>

          </Grid>*/}

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ marginLeft: "50px", marginTop: "5px" }}
            className="logo-grid"
          >
            <img
              src={currentImage}
              alt="bg"
              className="background-1"
              style={{ marginLeft: "0px" }}
            ></img>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              marginLeft: "4%",
              marginRight: "4%",
              background: "white",
              borderTopLeftRadius: "32px",
              borderTopRightRadius: "32px",
            }}
            className={forgot}
          >
            <div className={classes.coperate_section}>
              <div>
                <form className="cont" style={{ display: "flex", gap: "10px" }}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    style={{ marginTop: "20px" }}
                  >
                    <b className={classes.coperate_btn}>Corporate Login</b>
                    <h2 className="auth-content-align5">
                      <div className="welcome">
                        <strong>Welcome</strong>
                      </div>
                    </h2>
                    <div className="auth-content-align23">
                      Enter your login details here
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <div className="auth-content-align2">
                      <b>Company ID / E-mail</b>
                      <Input
                        item
                        xs={12}
                        id="user_name"
                        onChange={(e) => handle(e)}
                        className={classes.input}
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              className={classes.msg_icon}
                              src="./Images/email.svg"
                            ></img>
                          </InputAdornment>
                        }
                      />
                   
                      {res? (
                        <>
                          <span className={classes.errorVal}>
                          {res}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <div className="auth-content-align1">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingRight: "5px",
                        }}
                      >
                        <span>
                          {" "}
                          <b>Password</b>
                        </span>
                        <span
                          style={{
                            color: "131C4C",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={changeClass}
                        >
                          {" "}
                          <u>Forgot Password?</u>
                        </span>
                      </div>

                      <Input
                        onChange={(e) => handle(e)}
                        id="password"
                        type={newPass ? "text" : "password"}
                        className={classes.input}
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              className={classes.msg_icon}
                              src="./Images/key.svg"
                            ></img>
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ paddingRight: "10px", cursor: "pointer" }}
                          >
                            <img
                              onClick={showPass}
                              className={classes.msg_icon}
                              src="../Images/eye.png"
                            ></img>
                          </InputAdornment>
                        }
                      />
                      {errorFlagPass === true ? (
                        <>
                          <span className={classes.errorVal}>
                            Enter Password
                          </span>
                        </>
                      ) : null}
                    
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    style={{
                      marginTop: "28px",
                      textAlign: "center !important",
                    }}
                  >
                    <div className="auth-content-align">
                      <Button className={classes.login} onClick={() => login()}>
                        Log-In
                      </Button>
                    </div>
                  </Grid>
                  {/*
          <div className='auth-content-align center'>
            <Grid item xs={12} md={12} lg={12} style={{marginTop: "40px"}}>

              or

            </Grid>
          </div>
          <div className='auth-content-align1'>
            <Grid item xs={12} md={4} lg={4} className={classes.extra_login} style={{marginTop: "30px"}}>
              
                
                    <img className={classes.share} src='./Images/g1.png'></img>
                 
           
                  <img className={classes.share} src='./Images/g2.png'></img>
          

                <a href="https://www.linkedin.com/company/jiffy-delivery-services/" target="_blank">
                  <img className={classes.share} src='./Images/g3.png'></img>
                  </a>
              
            
            </Grid>
          </div>
              */}
                </form>
              </div>
            </div>

            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              style={{ marginLeft: "50px", marginRight: "20px" }}
              className={change}
            >
              <div
                className={classes.coperate_section}
                style={{ marginRight: "20px" }}
              >
                <b className={classes.coperate_btn}>Corporate Login</b>
                <h2 className="auth-content-align2">
                  <div className="welcome2">
                    <strong>Change/Reset Password</strong>
                  </div>
                </h2>
                <div className="newpass">
                  Create a new password that is at least 8 characters long
                </div>
                <form>
                  <div className="auth-content-align2">
                    <b>Enter Email Address</b>
                    <Input
                      item
                      xs={12}
                      id="user_name"
                      onChange={(e) => handle(e)}
                      className={classes.input}
                      startAdornment={
                        <InputAdornment position="start">
                          <img
                            className={classes.msg_icon}
                            src="./Images/email.svg"
                          ></img>
                        </InputAdornment>
                      }
                    />
                  </div>
                </form>

                <div className="auth-content-align2">
                  <Button
                    className={classes.login}
                    onClick={() => requestLink()}
                  >
                    Request Reset Link
                  </Button>
                </div>
              </div>
            </Grid>

            {errorFlag || errorFlagPass}
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                marginLeft: "4%",
                marginRight: "4%",
                background: "white",
                borderTopLeftRadius: "32px",
                borderTopRightRadius: "32px",
              }}
              className={Login}
            >
              {/* <Grid item xs={12} md={5} lg={5} style={{marginLeft: "50px",marginRight: "20px"}} > */}

              <div
                style={{
                  marginRight: "20px",
                  paddingLeft: "40px",
                  paddingTop: "5px",
                }}
              >
                <b className={classes.coperate_btn}>Corporate Login</b>
                <h2 className="auth-content-align25">
                  <div className="welcome2">
                    <strong>Forgot Your Password</strong>
                  </div>
                </h2>

                {!forSuccess ? (
                  <>
                    <div className="auth-content-align25">
                      Please enter the email address you’d like your password
                      reset information sent to
                    </div>
                    <form className="extra-for" style={{ display: "flex" }}>
                      <Grid item xs={12} md={4} lg={4}>
                        <div className="auth-content-align2">
                          <b>Enter Email Address</b>
                          <Input
                            item
                            xs={12}
                            id="email"
                            onChange={(e) => handle1(e)}
                            className={classes.input}
                            startAdornment={
                              <InputAdornment position="start">
                                <img
                                  className={classes.msg_icon}
                                  src="./Images/email.svg"
                                ></img>
                              </InputAdornment>
                            }
                          />
                        </div>
                      </Grid>{" "}
                      &nbsp;&nbsp;
                      <Grid item xs={12} md={4} lg={4}>
                        <div
                          className="authForgot"
                          style={{ marginTop: "50px" }}
                        >
                          <Button
                           className={isButtonDisabled ? classes.loginButtonDis : classes.login2}
                            //className={classes.login2}
                            onClick={() => requestLink()}
                            disabled={!forgotFlag}
                          >
                            Request Reset Link
                          </Button>
                        </div>
                      </Grid>
                    </form>
                  </>
                ) : (
                  <>
                    <div className="authForgoted">
                      <span>
                        <img
                          className="emailImg"
                          src="./Images/check.png"
                        ></img>
                      </span>
                      <br />
                      <span className="emailed"> Email Sent Successfully</span>
                    </div>
                  </>
                )}
              </div>
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} md={12} lg={12} className={classes.sepa}>
        <img className={classes.separt} src="./Images/seperator.png"></img>
      </Grid>
    </>
  );
};
export default withStyles(AuthenticationStyle)(AuthContent);
